<template>  
  <msal
    v-model="on"
  >
    <msal-functions :ref="refMsalFunction"></msal-functions>
    <slot v-if="ready"></slot>
    <v-main v-else style="height:100%;background-color: #fff">
      <v-container
        fluid
        grid-list-x
        fill-height
        justify-center
        pt-1
      >
        <skeleton></skeleton>
      </v-container>
    </v-main>
  </msal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { redirUrl } from '@/config'
import { xPost } from '@/functions/http/axios'
import Msal from '@/components/msal'
import MsalFunctions from '@/components/msal/functions/index.vue'
import Skeleton from './Skeleton.vue'

export default {
  data () { return {
    on: false,
    ready: false,
    refNo: Date.parse(new Date()),
  }},
  components: {
    Msal,
    MsalFunctions,
    Skeleton,
  },
  computed: {
    ...mapState('user', {
      msal: state => state.msal,
      myRoutePath: state => state.myRoutePath,
    }),
    ...mapGetters('user', [
      'loginStatus'
    ]),
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
  },
  methods: {
    ...mapActions('user', {
      'setMyRoutePath': 'setMyRoutePath',
      'setMsalAccount': 'setMsalAccount',
      'setUserInfo': 'setInfo',
      'setOnedriveId': 'setOnedriveId',
    }),
    
    // function
    async setupByMsalAccess ({ defaultEmail = null }) {
      this.$eventBus.$emit(
        'msal-get-access',
        null,
        async data => {
          const email = (this._.get(data, 'account.username') || '').toLowerCase()
          const name = this._.get(data, 'account.name') || ''
          const accTok = this._.get(data, 'accessToken') || null
          const homeId = this._.get(data, 'account.homeAccountId') || null
          if (email && accTok && homeId && (!defaultEmail || email === defaultEmail)) {
            this.setMsalAccount({ email, name, homeId, accTok })
            try {
              const res = await xPost(
                'general',
                'login',
                {
                  loginStatus: this.loginStatus
                }
              )
              if (res.data.status === 200) {
                if (!this.$route.path.indexOf('/login')) {
                  this.$router.push(this.myRoutePath || '/')
                }
                data = res.data
                this.setOnedriveId(this._.get(data, 'oid'))
                const getUserInfo = this.$refs[this.refMsalFunction].getUserInfo()
                const getUserserPhoto = this.$refs[this.refMsalFunction].getUserBase64Image({size: 48})
                const userInfo = await getUserInfo
                const userserPhoto = await getUserserPhoto
                this.setUserInfo({
                  team: this._.get(userInfo, 'department'),
                  location: this._.get(userInfo, 'officeLocation'),
                  costCenter:
                    this._.get(data, 'fix_cost_center')
                    || (this._.get(userInfo, 'onPremisesExtensionAttributes.extensionAttribute6') || '').split('-')[0],
                  photoUrl: (userserPhoto) || '',
                })
                this.updateUserMap(email)
              } else {
                throw ''
              }
              setTimeout(() => { this.setupByMsalAccess({ defaultEmail: email }) }, 3601000) // ~1h
            } catch (err) {
              console.log('Error [general - login]')
              // console.log(err)
              this.$router.push('/login')
            }
          } else {
            try {
              await xPost(
                'general',
                'logout',
                {
                  loginStatus: this.loginStatus
                }
              )
            } catch (err) {
              console.log('Error [general - logout]')
              // console.log(err)
            }
            if (this.$route.path !== '/login') {
              this.setMyRoutePath(this.$route.fullPath || '')
              this.$router.push('/login')
            }
          }
          this.ready = true
        }
      )
    },
    async loginMsal () {
      await this.msal.app.handleRedirectPromise()
      await this.msal.app.loginRedirect({
        scopes: this.msal.config.scopes,
        redirectUri: redirUrl
      })
    },
    logoutMsal () {
      this.msal.app.logoutRedirect({
        account: this.msal.app.getAccountByHomeId(this.msal.homeId),
        postLogoutRedirectUri: redirUrl,
      })
    },
    async updateUserMap (email) {
      if (!email) { return }
      try {
        const res = await xPost(
          'user',
          'updateUserMap',
          {
            loginStatus: this.loginStatus,
            email,
          }
        )
        if (res.data.status === 200) {
          // tbc
        } else {
          throw ''
        }
      } catch (err) {
        console.log('Error [updateUserMap - login]')
        // console.log(err)
      }
    },
  },
  created () {
    this.$eventBus.$on('msal-login', this.loginMsal)
    this.$eventBus.$on('msal-logout', this.logoutMsal)
  },
  beforeDestroy () {
    this.$eventBus.$off('msal-login', this.loginMsal)
    this.$eventBus.$off('msal-logout', this.logoutMsal)
  },
  watch: {
    async on (nv, ov) {
      if (nv && !ov) {
        await this.msal.app.handleRedirectPromise()
        await this.setupByMsalAccess({})
      }
    }
  }
}
</script>

<style>

</style>