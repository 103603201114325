<template>
  <div 
    id="vue-msal-component"
    class="fill-height"
  >
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as MsalApi from '@azure/msal-browser'

export default {
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
    authParams: {
      type: Object,
      default: () => ({})
    }
  },
  model: {
    prop: 'ready',
    event: 'change',
  },
  computed: {
    ...mapState('user', {
      msal: state => state.msal
    })
  },
  methods: {
    ...mapActions('user', [
      'setMsalApp',
    ]),
    async triggerSetMsal() {
      // const msal = new MsalApi.ConfidentialClientApplication({
      const msalApp = new MsalApi.PublicClientApplication({
        auth: {
          clientId: this.msal.config.clientId,
          // authority: `https://login.microsoftonline.com/organizations`
          authority: `https://login.microsoftonline.com/${this.msal.config.tenantId}`,
          ...this.authParams,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      })
      this.setMsalApp(msalApp)
      setTimeout(this.triggerSetMsal, 3605000) // 1d
    },
    async getAccess() {
      const accounts = this.msal.app.getAllAccounts()
      if (!(accounts instanceof Array) || !accounts.length) {
        return {
          msg: 'account'
        }
      }
      const request = {
        account: accounts[0],
        scopes: this.msal.config.scopes,
      }
      try {
        const response = await this.msal.app.acquireTokenSilent(request)
        return response
      }
      catch(err) {
        console.log('-----------------')
        console.log('Error [msal - access token]')
        // console.log(err)
        console.log('-----------------')
        return { msg: 'access' }
      }
    },

    // emit
    async emitGetAccess(params, callback) {
      if (!(callback instanceof Function)) { return }
      const response = await this.getAccess()
      callback(response)
    },

    // tool    
    requiresInteraction(errorCode) {
      if (!errorCode || !errorCode.length) {
        return false;
      }
      return errorCode === "consent_required" ||
        errorCode === "interaction_required" ||
        errorCode === "login_required"
    }
  },
  async created () {
    await this.triggerSetMsal()
    this.$eventBus.$on('msal-get-access', this.emitGetAccess)
    this.$emit('change', true)
  },
  beforeDestroy () {
    this.$eventBus.$off('msal-get-access', this.emitGetAccess)
  },
}
</script>

<style lang="sass">
  .vue-msal-component
    display: flex
</style>